<template>
  <div class="wrapper">
   <div class="change">
     <div class="updateRow">
       <span>当前密码</span>
       <el-input v-model="form.old_password" placeholder="请输入原密码"></el-input>
     </div>
     <div class="updateRow">
       <span>新密码</span>
       <el-input v-model="form.new_password" placeholder="请输入新密码"></el-input>
     </div>
     <div class="updateRow">
       <span>确认密码</span>
       <el-input v-model="form.affirm_password" placeholder="再次输入密码"></el-input>
     </div>
     <div class="submitBtn">
       <el-button size="mini" @click="submit">提交</el-button>
     </div>
   </div>
  </div>
</template>

<script>
import { editPassword } from '@/api';
import {createNamespacedHelpers} from 'vuex';
const {mapActions} = createNamespacedHelpers("user")
export default {
  data() {
    return {
      userInfo:"",
      form: {
        old_password: "",
        new_password: "",
        affirm_password: "",
      },
    };
  },
  methods: {
    ...mapActions(['userLogout']),
    async submit() {
      try{
        const res = await editPassword(this.form)
        if(res.status === 200) {
          //重新登录
          this.$success("修改成功,重新登录")
          setTimeout(() => {
            this.userLogout()
          },1000);
        }
      }catch(e) {
        // this.$error(e.message)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 260px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  .change{
    height: 220px;
    width: 450px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .updateRow{
      width: 100%;
      display: flex;
      margin-top: 20px;
      span{
        width: 100px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: #E4E4E4;
        opacity: 1;
        border-radius: 4px;
        margin-right: 20px;
        flex-shrink: 0;
      }
    }
    .submitBtn{
      button{
        margin-top: 30px;
        width: 76px;
        height: 36px;
        background: #E32322;
        opacity: 1;
        border-radius: 4px;
        color: #ffff;
      }
    }
  }
}
::v-deep .el-input__inner{
  height: 36px;
}
</style>
